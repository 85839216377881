
import { defineComponent } from 'vue'
import IOPageHeader from '@/components/IOPage/IOPageHeader.vue'
import IOPageOverview from '@/components/IOPage/OverviewTab/IOPageOverview.vue'
import baseViewMixin from '@/mixins/baseViewMixin'
import { generalMixin } from '@/mixins/generalMixin'
import IOForm from '@/components/TableComponents/Form/IOForm.vue'
import IoFormMixin from '@/mixins/ioFormMixin'
import { Baseline, Instruction, IoBrief } from '../../types/instruction_type'
import { mapMutations } from 'vuex'
import { getIoField } from '../../utils/instructionsUtils'
import { instructionsCaller } from '@/mixins/instructionsCaller'
import OutcomesDialog from '@/components/TableComponents/Outcomes/OutcomesDialog.vue'

export default defineComponent({
  name: 'IOPage',
  components: { OutcomesDialog, IOForm, IOPageOverview, IOPageHeader },
  mixins: [baseViewMixin, generalMixin, IoFormMixin, instructionsCaller],
  data () {
    return {
      loading: false,
      ioDatastore: {} as any,
      overview: {},
      dsp: this.$route.params.dsp,
      ioId: this.$route.params.external_id,
      instructionSortedByIo: this.$store.getters.getInstructionSortedByIo,
      editedIOIndex: this.$store.getters.getEditedIOIndex,
      openTab: 'tab-1'
    }
  },
  async mounted () {
    this.onMounted()
    const data = {
      dsp: this.dsp,
      search: this.ioId
    }
    this.loading = true
    const res = await this.$apiCaller.getInstructions(data)
    if (this.$apiCaller.isResponseError(res)) {
      console.warn('Error when calling instruction')
      this.$store.commit('setErrorMessageWithResponse', res)
      this.loading = false
      this.ioDatastore = {}
    } else {
      // TODO: ioDatastore becomes the first element in res.data to have a dsp equal to this.dsp and an external_id equal to this.ioId
      this.ioDatastore = res.data[0]
      this.loading = false
    }
    const res2 = await this.$apiCaller.getIoPageOverview(this.dsp, this.ioId)
    console.log(res2)
    if (this.$apiCaller.isResponseError(res2)) {
      console.warn('Error when calling instruction')
      this.$store.commit('setErrorMessageWithResponse', res2)
      this.loading = false
      this.overview = {}
    } else {
      // TODO: ioDatastore becomes the first element in res.data to have a dsp equal to this.dsp and an external_id equal to this.ioId
      this.overview = res2.data
      this.loading = false
    }
  },
  methods: {
    ...mapMutations(['setErrorMessage', 'editStartUpdate', 'editEndUpdate']),
    getIOField () {
      return getIoField(this.dsp)
    },
    /**
     * Call the api for the io,
     * update the instruction in the collection
     * and open the IoForm
     * @param item {import('../../../types/instruction_type').IoBrief}
     */
    async editItem (item: any) {
      this.editedIOIndex = item[this.getIOField()]
      // 1. call api for each id
      this.editStartUpdate()
      let updatedInstruction : any[] = []

      const data = {
        dsp: this.dsp,
        [this.getIOField()]: item[this.getIOField()]
      }

      const response = await this.$apiCaller.getInstructions(data)

      if (this.$apiCaller.isResponseError(response)) {
        this.$store.commit('setErrorMessageWithResponse', response)
        this.editEndUpdate()
        return
      }

      updatedInstruction = response.data

      // replace instruction per her updated version
      // 2. replace the instruction per the instruction received
      console.log('this.instructionSortedByIo', this.instructionSortedByIo)
      console.log('this.editedIOIndex', this.editedIOIndex)
      console.log('updatedInstruction', updatedInstruction)

      // 3. open the form
      this.$nextTick(() => {
        // get updated insertion_order
        let updatedItem = [this.ioDatastore]
        // update id_list
        updatedItem[0].id_list = updatedInstruction.map(item => item.id)
        const ioBrief = updatedItem[0]
        this.openEditForm(ioBrief, updatedInstruction)
        this.editEndUpdate()
      })
    },
    openEdit () {
      console.log('edit')
      this.openTab = 'tab-1'
      this.openEditForm(this.ioDatastore, this.ioDatastore.instructions)
    },
    openLogs () {
      this.openTab = 'tab-3'
      this.openEditForm(this.ioDatastore, this.ioDatastore.instructions)
    },
    openNotes () {
      this.openTab = 'tab-4'
      this.openEditForm(this.ioDatastore, this.ioDatastore.instructions)
    },
    openEditForm (ioBrief: IoBrief<any>, updatedInstructions: Instruction[]) {
      this.$authModule.checkIfRefreshIsNeeded()
      this.$store.commit('setFormStatus', this.$EDIT)
      this.editedInstructionsIO = this.$commonUtils.deepCopy(updatedInstructions)
      this.editedItem = this.$commonUtils.deepCopy(ioBrief)
      this.dialog = true
    },
    async save (acknowledgments : any = null, baseline: any = null) {
      await this.editMultiProcess(acknowledgments, baseline)
      this.close()
    }, // end method update box
    /**
     * process called when form is in edit
     * emit a event 'delete-and-post-raw'
     * @param acknowledgments {AcknowledgmentInfo[]}
     * @param baseline {Baseline}
     */
    async editMultiProcess (acknowledgments: AcknowledgmentInfo[] = null, baseline: Baseline = null) {
      const result = await this.prepareEditMultiProcess()
      await this.deleteAndPostRaw([
        ...result.editedInstru,
        ...result.newInstru
      ], acknowledgments, result.toDeleteInstruId, baseline)
    }
  },
  computed: {
    formTitle () {
      return this.getFormStatus + ' item'
    },
    getFormStatus () : any {
      return this.$store.getters.getFormStatus
    },
    keyIoForm () : any {
      return this.editedItem.id
    }
  }
})
