export function tooltipFunction (props) {
  return __tooltipManager(props, 'normal')
}

export function tooltipFunctionTarget (props) {
  return __tooltipManager(props, 'target')
}

export function __tooltipManager (props, mode) {
  let maxFreqString = ''
  let frequencyType = ''
  let overwriteName = ''

  if (mode === 'normal') {
    frequencyType = 'max_frequency'
    overwriteName = 'overwrite_frequency'
  } else if (mode === 'target') {
    frequencyType = 'target_frequency'
    overwriteName = 'overwrite_frequency_target'
  } else {
    throw Error('Not implemented !')
  }

  if (props.item[frequencyType] !== undefined && props.item[overwriteName] && Object.keys(props.item[frequencyType]).length >= 2) {
    let amount = props.item[frequencyType].amount !== undefined && props.item[frequencyType].amount !== null
      ? props.item[frequencyType].amount
      : ''
    let exposures = props.item[frequencyType].exposures !== undefined && props.item[frequencyType].exposures !== null
      ? props.item[frequencyType].exposures
      : 'NC'
    let period = props.item[frequencyType].period !== undefined && props.item[frequencyType].period !== null
      ? props.item[frequencyType].period
      : 'NC'
    maxFreqString = `${exposures} imp per ${amount} ${period}${period !== 'lifetime' ? '(s)' : ''}`
  }
  return maxFreqString
}

export const unknownDemographicConf = {
  label: 'overwrite unknown demographic',
  value: 'overwrite_unknown_demographic',
  isOrange: function (props) {
    return props.item.overwrite_unknown_demographic === 'only_if'
  },
  trueValue: 'always'
}
