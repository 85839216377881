
import { defineComponent } from 'vue'
import CopyClipboardComponent from '@/components/Common/CopyClipboardComponent.vue'

export default defineComponent({
  name: 'IOPageHeader',
  components: { CopyClipboardComponent },
  props: ['ioId', 'dsp', 'item'],
  data () {
    return {
      advertiserId: 'Advertiser ID',
      memberId: 'Member ID',
      advertiserName: 'Advertiser name',
      memberName: 'Member name',
      ioName: 'IO name',
      isHoverMember: false,
      isHoverAdvertiser: false,
      isHoverIo: false
    }
  },
  mounted () {
    this.callApi()
  },
  methods: {
    redirectToMember () {
      this.$router.push({
        name: 'surcoucheV2',
        params: { dsp: this.dsp, tab: 'insertionOrder' },
        query: { search: this.memberId }
      })
    },
    redirectToAdvertiser () {
      this.$router.push({
        name: 'surcoucheV2',
        params: { dsp: this.dsp, tab: 'insertionOrder' },
        query: { search: this.advertiserId }
      })
    },
    redirectToIoView () {
      this.$router.push({ name: 'surcoucheV2', params: { dsp: this.dsp, tab: 'insertionOrder' } })
    },
    async callApi () {
      console.log(this.ioId, this.advertiserId, this.memberId)
      const search = {
        search: this.ioId,
        dsp: this.dsp
      }
      const response = await this.$apiCaller.getOverviewsWithSearch(search)
      if (!this.$apiCaller.isResponseError(response)) {
        const data = (response as any).data
        if (data.length > 0) {
          this.advertiserId = data[0].advertiser.external_id
          this.memberId = data[0].member.external_id
          this.advertiserName = data[0].advertiser.name
          this.memberName = data[0].member.name
          this.ioName = data[0].name
        }
      } else {
        this.$store.commit('setErrorMessageWithResponse', response)
      }
    },
    openEdit () {
      this.$emit('edit')
    },
    openOutcomes () {
      this.$emit('outcomes')
    },
    openLogs () {
      this.$emit('logs')
    },
    openNotes () {
      this.$emit('notes')
    },
    openSearch () {
      this.$emit('search')
    }
  },
  watch: {
  }
})
