
import { defineComponent } from 'vue'
import { getClassStratLvl } from '../../../../utils/stratLvlUtils'
import { mdiAccount } from '@mdi/js'

export default defineComponent({
  name: 'IOPageOverviewSettings',
  props: ['ioDatastore'],
  methods: {
    getClassStratLvl
  },
  icons: {
    mdiAccount
  },
  computed: {
    iconOttoMinViz (): string {
      return this.ioDatastore.otto_min_viz ? 'visibility' : 'visibility_off'
    },

    isAbTest (): boolean {
      return this.ioDatastore.baseline && this.ioDatastore.baseline.type === 'ab_test'
    }
  }
})
