
import Component, { mixins } from 'vue-class-component'
import { activateMixin } from '../../../../../mixins/activateMixin'
import { FormTitle } from '../../FormFragments'
import OnOffInput from '../../../../KeystoneV2/tools/OnOffInput.vue'
import * as rules from '../../../../../rules/rules'
import { checkGroupKeyMixin } from '../../../../../mixins/checkGroupKeyMixin'
import { snakeCaseConverterMixin } from '../../../../../mixins/snakeCaseConverterMixin'
import MarkupModuleForm from '../../../../Common/MarkupModule.vue'
import InstructionGetterSetter from '../../../../../models/Overlay/InstructionGetterSetter'
import { Prop } from 'vue-property-decorator'
import SiegeModel from '../../../../../models/Keystone_v2/SiegeModel'
import { DspInstruction, IoBrief } from '../../../../../../types/instruction_type'
import { MarkupModuleModel } from '../../../../../models/Keystone_v2/MarkupModuleModel'
import { $BEESWAX, $DBM } from '../../../../../../config/dspConfig'
import SiegeSettingModel from '@/models/Keystone_v2/SiegeSettingModel'
import { isNotNullOrUndefined } from '../../../../../../utils/commonUtils'

@Component({
  components: {
    OnOffInput,
    FormTitle,
    MarkupModuleForm
  }
})
export default class BriefMarkupModule extends mixins(checkGroupKeyMixin, activateMixin, snakeCaseConverterMixin) {
  @Prop({ required: false, default: null }) insGetterSetter: InstructionGetterSetter
  @Prop({ required: false, default: null }) inheritedSiege: SiegeModel
  @Prop({ required: false, default: null }) editedItem: IoBrief<DspInstruction>
  @Prop({ required: false, default: null }) keystoneSettingSiege: SiegeSettingModel
  @Prop({ required: false, default: function (): MarkupModuleModel { return new MarkupModuleModel() } }) inheritedMarkupModule: MarkupModuleModel

  isMarkupModuleLoaded: boolean = false
  showMarkupModule: boolean = true
  rules = rules

  // Markup module is loaded in child. Function to launch when child emits
  markupModuleLoaded () {
    this.isMarkupModuleLoaded = true
  }

  getConvertedMarkupModule () {
    const ref: MarkupModuleForm = this.$refs.markupModuleForm as MarkupModuleForm
    return ref.getConvertedMarkupModule()
  }

  get isBeeswax () {
    return this.$route.params.dsp === $BEESWAX
  }

  get isMediaSavingsDbm (): boolean {
    return isNotNullOrUndefined(this.inheritedMarkupModule) && this.inheritedMarkupModule.action === 'media_savings' && this.dsp === $DBM
  }

  get markupModuleConverted (): MarkupModuleModel {
    return new MarkupModuleModel(this.insGetterSetter.markupModule)
  }

  set markupModuleConverted (newValue: MarkupModuleModel) {
    this.insGetterSetter.markupModule = newValue.getObjectForApi()
  }

  get formPartWithError () {
    return this.$store.getters.getFormPartWithError
  }

  get hasErrorMarkupModule () {
    return this.formPartWithError.includes('markupModule')
  }
}
