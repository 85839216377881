
import { defineComponent } from 'vue'
import IOPageOverviewSettings from '@/components/IOPage/OverviewTab/IOPageOverviewSettings.vue'
import IOPageOverviewOverwrites from '@/components/IOPage/OverviewTab/IOPageOverviewOverwrites.vue'
import IOPageOverviewFlight from '@/components/IOPage/OverviewTab/IOPageOverviewFlight.vue'
import IoPageOverviewStatuses from '@/components/IOPage/OverviewTab/IoPageOverviewStatuses.vue'

export default defineComponent({
  name: 'IOPageOverview',
  components: { IoPageOverviewStatuses, IOPageOverviewFlight, IOPageOverviewOverwrites, IOPageOverviewSettings },
  props: ['ioDatastore', 'overview', 'dsp'],
  data () {
    return {
      loading: false,
      ioId: this.$route.params.external_id
    }
  },
  computed: {
  }
})
