
import { defineComponent } from 'vue'
import _ from 'lodash'
import { isNotNullOrUndefined } from '../../../../utils/commonUtils'

export default defineComponent({
  name: 'IOPageOverviewFlight',
  props: ['overview'],
  computed: {
    flightTitle (): string {
      if (this.currentFlightRemaining < 0) {
        return 'No current flight'
      }
      return `${this.currentFlightRemaining} days`
    },
    flightSubtitle (): string {
      if (this.currentFlightRemaining < 0) {
        return ''
      }
      return `Started on ${this.currentFlightStartDate}`
    },
    nextFlightTitle (): string {
      if (!isNotNullOrUndefined(this.nextFlightStartDate)) {
        return 'No upcoming flight scheduled'
      }
      return 'Next flight:'
    },
    nextFlightSubtitle (): string {
      if (!isNotNullOrUndefined(this.nextFlightStartDate) || !isNotNullOrUndefined(this.nextFlightEndDate)) {
        return ''
      }
      return `${this.nextFlightStartDate} - ${this.nextFlightEndDate}`
    },
    currentFlightStartDate (): string {
      return this.overview?.overview?.billing_start
    },
    currentFlightRemaining (): number {
      // create date for yesterday
      const yesterday = new Date()
      yesterday.setDate(yesterday.getDate() - 1)
      const endDate = new Date(this.overview?.overview?.billing_end)
      const today = new Date()
      // get days difference between today and end date
      return _.floor((endDate.getTime() - today.getTime()) / (1000 * 3600 * 24))
    },
    nextFlightStartDate (): string {
      return this.overview?.overview?.next_billing_start
    },
    nextFlightEndDate (): string {
      return this.overview?.overview?.next_billing_end
    },
    remainingBudgetText (): string {
      return this.remainingBudget && this.remainingBudget >= 0 ? `${this.remainingBudget}` : 'No remaining budget'
    },
    totalBudgetText (): string {
      return this.totalBudget && this.totalBudget >= 0 ? `${this.totalBudget}` : 'No set budget'
    },
    totalBudget (): number {
      return this.overview?.budget?.flight_budget
    },
    remainingBudget (): number {
      return this.overview?.budget?.flight_budget_spent
    }
  }
})
