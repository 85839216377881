

import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, PropSync, Watch } from 'vue-property-decorator'
import DatePicker from '@/components/TableComponents/Form/FormFragments/DatePicker.vue'

@Component({
  components: {
    DatePicker
  }
})
export default class DuoDatePicker extends Vue {
  @Prop({ default: 'Start date' })
  labelStart: string
  @Prop({ default: 'End date' })
  labelEnd: string

  @Prop({ default: true })
  exclusive: boolean

  @Prop({ default: false })
  clearable: boolean

  @PropSync('start', { type: String })
  startDate: string
  @PropSync('end', { type: String })
  endDate: string
  @Prop()
  required: boolean
  @Prop()
  disabled: boolean
  @Prop({ default: (): string[] => [] })
  errorMessages: string[]

  currentErrorMessages: string[] = []

  checkStartDateEndDate () {
    if (!this.startDate || !this.endDate) {
      return []
    }
    const errors = []
    const dateStart = new Date(this.startDate)
    const dateEnd = new Date(this.endDate)
    if (!this.datesAreOrdered(dateStart, dateEnd)) {
      errors.push('Start date must be before end date')
    }

    return errors
  }

  allErrorMessages () {
    const errors = this.checkStartDateEndDate()
    return errors.concat(this.errorMessages)
  }

  checkErrorMessages () {
    this.currentErrorMessages = this.allErrorMessages()
  }

  datesAreOrdered (startDate: Date, endDate: Date) {
    if (this.exclusive) {
      return startDate < endDate
    } else {
      return startDate <= endDate
    }
  }

  @Watch('startDate')
  onChangeStartDate () {
    this.checkErrorMessages()
  }

  @Watch('endDate')
  onChangeEndDate () {
    this.checkErrorMessages()
  }
}

